<template>
  <div></div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "confirm_user",
  layout: "guest",
  auth: false,
  methods: {
    ...mapActions('auth', ['login']),
    async doLogin() {
      try {
        let res = await this.login(
              {
                otp: this.$route.params.id
              })
        console.log(res)
        await this.$router.push("/")
      } catch (e) {
        console.log(e)
        if (e.response.status == 412) {
          this.$toast.error('User is not verified', {position: 'top-center', duration: 3000});
        } else if(e.response.status == 400) {
          this.$toast.error('Wrong credentials', {position: 'top-center', duration: 3000});
        }
      }

    }
  },
  mounted() {
    this.doLogin()
  }
}
</script>

<style scoped>

</style>
